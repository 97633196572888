<template>
  <div>
    <!--      左侧菜单栏-->
    <a-col :span="4">
      <a-menu
        style="min-height: 300px"
        mode="vertical"
        :default-selected-keys="activeMenuTab"
        v-model="activeMenuTab"
        v-if="isShowMenu('traffic_pools/show')"
      >
        <a-menu-item
          v-for="item in showHasPermissionMenus"
          :key="item.routeName"
        >
          <router-link :to="{ name: item.routeName, params: { id: agentId, traffic_pool_id: trafficPoolId }}">
            <a-icon :type="item.iconType" />
            {{ item.menuName }}
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-col>
    <!--      内容-->
    <a-col :span="20">
      <div class="simiot-vertical-tab-content">
        <a-breadcrumb class="bread-crumb">
          <a-breadcrumb-item>
            <router-link :to="{ name: 'agent_traffic_pools' }">
              流量池列表
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>{{ this.trafficPoolName }}</a-breadcrumb-item>
        </a-breadcrumb>

        <router-view />
      </div>
    </a-col>
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findTrafficPoolName } from '@/api/traffic_pool'

export default {
  name: 'TrafficPoolShowTab',
  data() {
    return {
      activeMenuTab: [],
      allMenus: [
        { routeName: 'agent_traffic_pool_info', menuName: '流量池信息概览', iconType: 'appstore', permission: 'traffic_pools/show' },
        { routeName: 'agent_traffic_pool_sim_cards', menuName: '流量池卡号列表', iconType: 'profile', permission: 'traffic_pools/show' },
        { routeName: 'agent_traffic_pool_expand_records', menuName: '流量池扩容记录', iconType: 'profile', permission: 'traffic_pools/show' },
        { routeName: 'agent_traffic_pool_usage_orders', menuName: '财务及流量信息', iconType: 'pay-circle', permission: 'traffic_pools/show' }
      ],
      showHasPermissionMenus: [],
      trafficPoolName: ''
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    },
    trafficPoolId() {
      return parseInt(this.$route.params.traffic_pool_id)
    }
  },
  watch: {
    trafficPoolId() {
      this.fetchBreadcrumbData()
    }
  },
  created() {
    this.activeMenuTab = [this.$route.name]
    this.fetchShowHasPermissionMenus()
    this.fetchBreadcrumbData()
  },
  methods: {
    fetchBreadcrumbData() {
      findTrafficPoolName(this.trafficPoolId).then((res) => {
        if (res.code === 0) {
          this.trafficPoolName = res.data.name
        }
      })
    },

    fetchShowHasPermissionMenus() {
      let isIncludeActiveMenuTab = false

      this.allMenus.forEach((value, index) => {
        if (this.isShowMenu(value.permission)) {
          this.showHasPermissionMenus.push(value)
          if (this.activeMenuTab[0] === value.routeName) {
            isIncludeActiveMenuTab = true
          }
        }
      })

      // 如果有权限的目录中不包含activeKey，那么activeKey 等于目录中第一个路径名
      if (!isIncludeActiveMenuTab) {
        this.activeKey = this.showHasPermissionMenus[0].routeName
      }
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    }
  }
}
</script>

<style lang="less" scoped>
.bread-crumb {
  margin-bottom: 20px;
}
</style>
